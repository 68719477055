@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background: #ffffff;
}

body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 450px;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type='checkbox'],
label,
svg {
  cursor: pointer;
}
